'use strict'

### Sevices ###

angular.module('app.services', ['ngResource'])

.factory('version', -> "0.1")

.factory 'Api', ($resource) ->
  index: $resource '/api/index.json'
  users: $resource '/api/users/:nick.json', nick:'@nick'
  weeks: $resource '/api/weeks/:week.json', week:'@week'
