'use strict'

# Declare app level module which depends on filters, and services
App = angular.module('app', [
  'ngCookies'
  'ngResource'
  'ngRoute'
  'app.controllers'
  'app.directives'
  'app.filters'
  'app.services'
  'partials'
])

App.config([
  '$routeProvider'
  '$locationProvider'

($routeProvider, $locationProvider, config) ->

  $routeProvider

    .when('/users',
      controller: 'CookbookCtrl',
      templateUrl: '/partials/users.html'
    )
    .when('/weeks',
      controller: 'CookbookCtrl',
      templateUrl: '/partials/weeks.html'
    )
    .when('/week/:week',
      controller: 'WeekCtrl',
      templateUrl: '/partials/week.html'
    )
    .when('/user/:nick',
      controller: 'UserCtrl',
      templateUrl: '/partials/user.html'
    )

    # Catch all
    .otherwise({redirectTo: '/weeks'})

  # Without server side support html5 must be disabled.
  $locationProvider.html5Mode(false)
])
