'use strict'

### Controllers ###

angular.module('app.controllers', [
  'app.services'
  ])

.controller('AppCtrl', [
  '$scope'
  '$location'
  '$resource'
  '$rootScope'

($scope, $location, $resource, $rootScope) ->

  # Uses the url to determine if the selected
  # menu item should have the class active.
  $scope.$location = $location
  $scope.$watch('$location.path()', (path) ->
    $scope.activeNavId = path || '/'
  )

  # isActive compares the current url with the id.
  # If the current url starts with the id it returns 'active'
  # otherwise it will return '' an empty string. E.g.
  #
  #   # current url = '/products/1'
  #   isActive('/products') # returns 'active'
  #   isActive('/orders') # returns ''
  #
  $scope.isActive = (id) ->
    if $scope.activeNavId.substring(0, id.length) == id
      return 'active'
    else
      return ''
])

.controller('CookbookCtrl', [
  '$scope'
  'Api'

($scope,Api) ->
  $scope.index = Api.index.get()
  $scope.isActiveUser = (user) ->
    user.post_count >= 5
])

.controller('WeekCtrl', [
  '$scope'
  '$routeParams'
  'Api'

($scope,$routeParams,Api) ->
  $scope.weekData = Api.weeks.get week: $routeParams.week
])

.controller('UserCtrl', [
  '$scope'
  '$routeParams'
  'Api'

($scope,$routeParams,Api) ->
  $scope.userData = Api.users.get nick: $routeParams.nick
])
